.my-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    position: static;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #E7EBF1;
    box-shadow: 12px 12px 40px rgba(19, 46, 89, 0.1);
    border-radius: 20px;
    width: 100%;
}

.my-button {
    color: #30D4E3;
    padding: 12px 20px;
    width: 135px;
    height: 56px;
    background: #132E59;
    box-shadow: 8px 8px 20px rgba(19, 46, 89, 0.2);
    border-radius: 30px;
    flex: none;
    margin: 0px 10px;
}

.my-button:disabled {
    background: #858585;
    color: #FFFFFF;
}

.filter-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 5px 15px;
    height: 35px;
    background: #E7EBF1;
    border-radius: 30px;
    margin: 20px 0px;
}

.my-chip-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    height: 25px;
    left: calc(50% - 63px/2 - 90px);
    top: 0px;
    border: 1px solid #99ABC6;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 10px;
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    padding: 5px 10px;
}

.sucess-buble {
    width: 6px;
    height: 6px;
    left: calc(50% - 6px/2);
    top: calc(50% - 6px/2);
    margin-right: 8px;
    background: #20D6B7;
    border-radius: 50%;
}

.hidden {
    background: rgba(0, 0, 0, 0);
}

.my-row {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #C1CCDD;
    border-radius: 20px;
}
