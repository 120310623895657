.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.card {
    width: 100%;
    margin: 20px;
}

.card-body-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-item {
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: white;
    border: 1px solid grey;
}

.card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}