.usuario-card-title {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
}

.usuario-card-container {
  margin: 10px 0px;
  border: 1px solid grey;
  border-radius: 10px;
}

.usuario-card-container:hover {
  cursor: pointer;
  -webkit-box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
}
