.prescricao-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.prescription-label {
  border-bottom: 1px solid grey;
  margin: 20px 0px;
}

.prescription-label-container {
  margin: 20px 0px;
}

.card-receita-assinada {
  font-weight: 700;
  color: lightseagreen;
  border-radius: 3px;
  margin: 10px 0px;
  margin-right: 10px;
}

.card-receita-n-assinada {
  font-weight: 700;
  color: lightcoral;
  border-radius: 3px;
  margin: 10px 0px;
  margin-right: 10px;
}

.prescricao-item-container {
  margin: 10px 0px;
}


.documentos-container {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px;
}

.documentos-item {
  font-size: 16px;
  font-weight: bold ;
  padding: 4px;
}

.documentos-title {
  font-size: 16px;
  font-weight: bold ;
  padding: 4px;
}