.tab-item {
  width: 100%;
  text-align: center;
  border: 1px solid grey;
  color: black;
}

.tab-item:hover {
  -webkit-box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.tab-item.selected {
  background-color: lightseagreen;
  color: white;
}

.tab-item.selected:hover {
  -webkit-box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
