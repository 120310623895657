.prescricao-card-container {
  width: 100%;
  border-radius: 10px;
  border: 1px solid grey;
  height: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.prescricao-card-title {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prescricao-card-body {
  padding: 10px;
}

.prescricao-card-footer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prescricao-card-unlock-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
