.page-icon {
  width: 120px;
  height: 120px;
}

.page-icon-container {
  width: 100%;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin: 20px;
}
