@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-container {
  padding: 2em;
}

#root {
  background-color: #f9f9ff;
  height: 100vh;
  font-family: Poppins;
  overflow-y: scroll !important;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 80px);
  min-width: 209px;
  background-color: #ffffff;
  padding: 20px 30px;
}

.nav-button {
  color: black;
  text-decoration: none;
  height: 40px;
  width: 100%;
  padding: 12px;
  padding: 0 12px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700px;
  font-size: 14px;
  background: #e7ebf1;
  border: none;
  border: 2px solid #e7ebf1;
  box-sizing: border-box;
  border-radius: 30px;
}

.nav-button-text {
  box-sizing: border-box;
  font-weight: 700;
}

.my-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    position: static;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #E7EBF1;
    box-shadow: 12px 12px 40px rgba(19, 46, 89, 0.1);
    border-radius: 20px;
    width: 100%;
}

.my-button {
    color: #30D4E3;
    padding: 12px 20px;
    width: 135px;
    height: 56px;
    background: #132E59;
    box-shadow: 8px 8px 20px rgba(19, 46, 89, 0.2);
    border-radius: 30px;
    flex: none;
    margin: 0px 10px;
}

.my-button:disabled {
    background: #858585;
    color: #FFFFFF;
}

.filter-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 5px 15px;
    height: 35px;
    background: #E7EBF1;
    border-radius: 30px;
    margin: 20px 0px;
}

.my-chip-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    height: 25px;
    left: calc(50% - 63px/2 - 90px);
    top: 0px;
    border: 1px solid #99ABC6;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 10px;
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    padding: 5px 10px;
}

.sucess-buble {
    width: 6px;
    height: 6px;
    left: calc(50% - 6px/2);
    top: calc(50% - 6px/2);
    margin-right: 8px;
    background: #20D6B7;
    border-radius: 50%;
}

.hidden {
    background: rgba(0, 0, 0, 0);
}

.my-row {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #C1CCDD;
    border-radius: 20px;
}

.tab-item {
  width: 100%;
  text-align: center;
  border: 1px solid grey;
  color: black;
}

.tab-item:hover {
  box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.tab-item.selected {
  background-color: lightseagreen;
  color: white;
}

.tab-item.selected:hover {
  box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.title-container {
  width: 100%;
  border-bottom: 2px solid grey;
  padding-bottom: 5px;
  margin-bottom: 20px;
}


.prescricao-card-container {
  width: 100%;
  border-radius: 10px;
  border: 1px solid grey;
  height: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.prescricao-card-title {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prescricao-card-body {
  padding: 10px;
}

.prescricao-card-footer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prescricao-card-unlock-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.busca-prescricao-container {
  padding-top: 20px;
}

.form-container {
  padding-bottom: 10px;
}


.medico-card-container {
  width: 100%;
  border-radius: 10px;
  border: 1px solid grey;
  height: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.medico-card-title {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.medico-card-body {
  padding: 10px;
}

.medico-card-footer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.medico-card-unlock-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.medico-card-body-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.icon-button {
  margin-left: 10px;
  font-size: 16px;
}

.icon-button:hover {
  box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.medico-card-body-item-editing {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.span-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-icon {
  width: 120px;
  height: 120px;
}

.page-icon-container {
  width: 100%;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin: 20px;
}


.meu-cadastro-container {
  width: 100%;
  margin: 2em;
}

.gerenciar-cadastros-container {
  width: 100%;
  height: 100%;
  margin: 2em;
}


.usuario-card-title {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
}

.usuario-card-container {
  margin: 10px 0px;
  border: 1px solid grey;
  border-radius: 10px;
}

.usuario-card-container:hover {
  cursor: pointer;
  box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
}

.usuarios-lista-container {
  margin: 2em 0em;
  width: 100%;
}

.prescricao-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.prescription-label {
  border-bottom: 1px solid grey;
  margin: 20px 0px;
}

.prescription-label-container {
  margin: 20px 0px;
}

.card-receita-assinada {
  font-weight: 700;
  color: lightseagreen;
  border-radius: 3px;
  margin: 10px 0px;
  margin-right: 10px;
}

.card-receita-n-assinada {
  font-weight: 700;
  color: lightcoral;
  border-radius: 3px;
  margin: 10px 0px;
  margin-right: 10px;
}

.prescricao-item-container {
  margin: 10px 0px;
}


.documentos-container {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px;
}

.documentos-item {
  font-size: 16px;
  font-weight: bold ;
  padding: 4px;
}

.documentos-title {
  font-size: 16px;
  font-weight: bold ;
  padding: 4px;
}
.row{ 
  margin-top: 5px
}
.rightAlign {
  text-align-last: end;
}
.buttonFullWidth{
  margin-top: 50px;
  width: 100%;
}
.rightAlign {
  text-align-last: end;
}

.rightAlign {
  text-align-last: end;
}
.rightAlign {
  text-align-last: end;
}
.buttonFullWidth{
  margin-top: 50px;
  width: 100%;
}

div.finalizar {
  margin-bottom: 0px;
}
.rightAlign {
  text-align-last: end;
}
.buttonFullWidth{
  margin-top: 50px;
  width: 100%;
}

div.finalizar {
  margin-bottom: 0px;
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.card {
    width: 100%;
    margin: 20px;
}

.card-body-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-item {
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: white;
    border: 1px solid grey;
}

.card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.rightAlign {
  text-align-last: end;
}
.buttonFullWidth{
  margin-top: 50px;
  width: 100%;
}

div.finalizar {
  margin-bottom: 0px;
}

.prescricao-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.prescription-label {
  border-bottom: 1px solid grey;
  margin: 20px 0px;
}

.prescription-label-container {
  margin: 20px 0px;
}

.card-receita-assinada {
  font-weight: 700;
  color: lightseagreen;
  border-radius: 3px;
  margin: 10px 0px;
  margin-right: 10px;
}

.card-receita-n-assinada {
  font-weight: 700;
  color: lightcoral;
  border-radius: 3px;
  margin: 10px 0px;
  margin-right: 10px;
}

.prescricao-item-container {
  margin: 10px 0px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item {
  text-decoration: none;
  color: whitesmoke;
  font-weight: 500;
  padding-bottom: 35px;
}

a:hover {
  text-decoration: none;
  color: lightskyblue;
}

.menu-container {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  background-color: #FFFFFF;
}

