.medico-card-container {
  width: 100%;
  border-radius: 10px;
  border: 1px solid grey;
  height: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.medico-card-title {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.medico-card-body {
  padding: 10px;
}

.medico-card-footer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.medico-card-unlock-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.medico-card-body-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.icon-button {
  margin-left: 10px;
  font-size: 16px;
}

.icon-button:hover {
  -webkit-box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 14px -3px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.medico-card-body-item-editing {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.span-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
