.rightAlign {
  text-align-last: end;
}
.buttonFullWidth{
  margin-top: 50px;
  width: 100%;
}

div.finalizar {
  margin-bottom: 0px;
}